/* eslint-disable quotes */
import React from "react";
import { observer, inject } from "mobx-react";
import Login from "./login/Login";

const AuthGuard = ({ children, tokenStore }) => {
	if (`${tokenStore.value}`.trim() !== "") {
		return children;
	}
	return <Login to={children} />;
};

export default inject("tokenStore")(observer(AuthGuard));
