/* eslint-disable  */
import { observable } from 'mobx';
import { persist, create } from 'mobx-persist';
import { configureStore } from '@reduxjs/toolkit';

class Sections {
  @persist('list') @observable value = [];

  @observable clear() {
  	this.value = [];
  }
}
class Token {
  @persist @observable value = '';

  @observable clear() {
  	this.value = '';
  }
}
class UserId {
  @persist @observable value = null;

  @observable clear() {
  	this.value = null;
  }
}
class User {
  @persist('object') @observable value = {};

  @observable clear() {
  	this.value = {};
  }
}
class Chatroom {
  @persist @observable value = '';

  @observable clear() {
  	this.value = {};
  }
}

class Permissions {
  @persist('list') @observable value = [];
  @observable clear() {
    this.value = [];
  }
}

const userStore = new User();
const tokenStore = new Token();
const sectionsStore = new Sections();
const user_id = new UserId();
const chatroom = new Chatroom();
const permissions = new Permissions();
const hydrate = create({
	storage: localStorage,
	jsonify: true,
});
hydrate('cs-user', userStore).then(() => {});
hydrate('cs-token', tokenStore).then(() => {});
hydrate('cs-permissions', permissions).then(() => {});
hydrate('cs-sections', sectionsStore).then(() => {});
hydrate('user-id', user_id).then(() => {});
hydrate('chatroom', chatroom).then(() => ({ test: true }));

const store = configureStore({
  reducer: {
  },
});
export default {
	userStore,
	tokenStore,
	sectionsStore,
	user_id,
	chatroom,
  permissions,
  store
};
