import axiosInstance from '../plugins/axios';

const axios = axiosInstance();

export const askForHelp = (body, token) =>
  axios.post('/asana/createTask', body, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'request-source': 'Admin-App',
    },
  });
export const askForHelpContacts = (token) =>
  axios.get('/accountManagers', {
    headers: {
      authorization: `Bearer ${token}`,
      'request-source': 'Admin-App',
    },
  });
