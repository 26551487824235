import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { observer, inject } from 'mobx-react';
import LezzooLogo from '../basic/LezzooLogo';
import LoginComponent from './LoginComponent';
import ResetPasswordComponent from './ResetPasswordComponent';

const Login = ({ tokenStore, to }) => {
	const [component, setComponent] = useState('login');

	const selectComponent = () => {
		switch (component) {
			case 'login':
				return <LoginComponent />;

			case 'reset_password':
				return <ResetPasswordComponent />;

			default:
				return <LoginComponent />;
		}
	};

	if (`${tokenStore.value}`.trim() !== '') {
		return to;
	}

	const renderLogin = selectComponent();

	return (
		<Row
			type="flex"
			align="middle"
			justify="center"
			style={{
				flexDirection: 'column',
				height: '100vh',
				width: '100%',
				padding: '20px',
				background:"#001529"
			}}
		>
			<Col style={{ marginBottom: '50px' }}>
				<LezzooLogo />
			</Col>
			<Col>{renderLogin}</Col>
		</Row>
	);
};

export default inject('tokenStore')(observer(Login));
