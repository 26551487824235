import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import ar from './locales/ar.json';
import kr from './locales/kr.json';

const resources = {
  en: {
    translation: en

  },
  ar: {
    translation: ar
  },
  kr: {
    translation: kr
  }
};

i18n
  // detect user language
  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    // resources: {
    //   en,
    //   ar,
    //   kr,
    // },
    resources,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;