import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Profile = ({ setVisible, userStore,updateMyPassword,logout }) => {
  const { t,i18n } = useTranslation();


  return (
    <Dropdown
      overlay={
        <Menu
          items={
            updateMyPassword
              ? [
                  {
                    label: (
                      <a onClick={() => setVisible(true)}>
                        {t('update_password')}
                      </a>
                    ),
                    key: '6',
                  },
                  {
                    type: 'divider',
                  },
                  {
                    label: (
                      <a href="# " onClick={logout}>
                        {t('logout')}
                      </a>
                    ),
                    key: '3',
                  },
                ]
              : [
                  {
                    label: (
                      <a href="# " onClick={logout}>
                        {t('logout')}
                      </a>
                    ),
                    key: '3',
                  },
                ]
          }
        />
      }
      on
      style={{
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          cursor: 'pointer',
          marginRight: 30,
        }}
      >
        <Avatar
          style={{
            // marginRight: 10,
            color: 'white',
            backgroundColor: '#001529',
            [i18n.language === 'en' ? 'marginRight' : 'marginLeft']: '.5rem',
          }}
          size={30}
          icon={<UserOutlined />}
        />
        <span>{userStore?.value?.user_name}</span>
      </div>
    </Dropdown>
  );
};

export default inject(
  'userStore',
 
)(observer(withRouter((props) => <Profile {...props} />)));
