import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import stores from './store/index';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import './i18n';

ReactDOM.render(
	<Provider {...stores}>
		<BrowserRouter>
			<ErrorBoundary>

				<App />
			</ErrorBoundary>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root'),
);
serviceWorker.unregister();

//   import React from 'react';
//   import ReactDOM from 'react-dom/client';
//   import { Provider } from 'mobx-react';
//   import { BrowserRouter } from 'react-router-dom';
//   import App from './App';
//   import stores from './store/index';

//   const rootElement = document.getElementById('root');
//   const root = ReactDOM.createRoot(rootElement);

//   root.render(
//     <Provider {...stores}>
//     <BrowserRouter>
//         <App />
//     </BrowserRouter>
//   </Provider>
// );
