import axios from 'axios';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member.
import store from '../store/index';

export default function getAgentInstance() {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_AXIOS_DEVELOPMENT_BASE_URL}/api`,
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${store.tokenStore.value}`,
      'request-source': 'Admin-App',
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        Promise.all([
          store.userStore.clear(),
          store.tokenStore.clear(),
          store.sectionsStore.clear(),
        ]).then(() => {});
        window.localStorage.clear();
        window.location.reload();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
  return instance;
}

export function axiosInstanceForImage(tokenStore) {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_AXIOS_DEVELOPMENT_BASE_URL}/api`,
    headers: {
      authorization: `Bearer ${tokenStore}`,
      'request-source': 'Admin-App',
    },
  });
  return instance;
}
