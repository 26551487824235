import React, { useState } from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import { observer, inject } from 'mobx-react';
import { login } from '../../api/authApi';
import { useHistory } from 'react-router-dom';

const LoginComponent = ({ userStore, tokenStore, user_id, permissions }) => {
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { data } = await login(values.username, values.password);
      user_id.value = data.data.user.userId;
      userStore.value = data.data.user;
      tokenStore.value = data.data.token;
      permissions.value = data.data.permissions;
      push('/');
    } catch (err) {
      notification.error({
        placement: 'bottomRight',
        message: "Can't login",
        description: 'Username or Password is wrong. Please try again.',
      });
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Card
      title="Login to continue"
      style={{
        fontSize: '40px',
        width: 500,
        padding: '50px 20px',
        backgroundColor: '#fff',
      }}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Username"
          name="username"
          dir="ltr"
          rules={[
            {
              required: true,
              message: 'Username is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          dir="ltr"
          rules={[{ required: true, message: 'Password is required' }]}
        >
          <Input.Password />
        </Form.Item>
        {/* <Link
					to="/forgot-password"
					style={{
						marginRight: 'auto',
						textAlign: 'right',
						color: '#1890ff',
						marginBottom: '10px',
						display: 'block',
					}}
				>
          Forgot Password?
				</Link> */}
        <Button
          htmlType="submit"
          block
          type="primary"
          loading={loading}
          style={{ backgroundColor: '#1890ff', border: 'none' }}
        >
          Login
        </Button>
      </Form>
    </Card>
  );
};

export default inject('userStore', 'tokenStore', 'user_id', 'permissions')(observer(LoginComponent));
