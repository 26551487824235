import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './askForHelp.css';

const AskForHelpCard = ({ index, title, children }) => {
  const [isOpen, setIsOpen] = useState(index === '1' ? true : false);

  const springProps = useSpring({
    height: isOpen ? 'auto' : 0,
    opacity: isOpen ? 1 : 0,

    config: {
      tension: 300, // Adjust tension for bounce effect
      friction: 20, // Adjust friction for smoother animation
      duration: 350, // Duration in milliseconds
    },
  });

  const toggleCard = (e) => {
    if (e.target.classList.contains('card') || e.target.classList.contains('card-title')) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div onClick={toggleCard} className="card">
      <div className="card-title">
        <h3>{title}</h3>
        <svg
          className={` arrow ${isOpen ? 'activeArrow' : ''}`}
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.04781 14.7662C0.859444 14.5778 0.765259 14.3548 0.765259 14.0971C0.765259 13.8399 0.859444 13.6171 1.04781 13.4287L6.56704 7.9095L1.02898 2.37144C0.853164 2.19563 0.765259 1.97587 0.765259 1.71215C0.765259 1.44843 0.859444 1.22239 1.04781 1.03402C1.23618 0.84565 1.45921 0.751465 1.7169 0.751465C1.97409 0.751465 2.19687 0.84565 2.38524 1.03402L8.71445 7.38207C8.7898 7.45742 8.8433 7.53904 8.87494 7.62695C8.90609 7.71486 8.92166 7.80904 8.92166 7.9095C8.92166 8.00997 8.90609 8.10415 8.87494 8.19206C8.8433 8.27996 8.7898 8.36159 8.71445 8.43694L2.3664 14.785C2.19059 14.9608 1.97409 15.0487 1.7169 15.0487C1.45921 15.0487 1.23618 14.9545 1.04781 14.7662Z"
            fill="#636363"
          />
        </svg>
      </div>
      <animated.div style={{ ...springProps }} className="faq-answer">
        {isOpen && children}
      </animated.div>
    </div>
  );
};

export default AskForHelpCard;
