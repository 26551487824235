import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="en">
            <a onClick={() => changeLanguage('en')}>{t('English')}</a>
          </Menu.Item>
          <Menu.Item key="kr">
            <a onClick={() => changeLanguage('kr')}>{t('کوردی')}</a>
          </Menu.Item>
          <Menu.Item key="ar">
            <a onClick={() => changeLanguage('ar')}>{t('عربی')}</a>
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
      arrow
      trigger={['click']}
    >
      <Button
        style={{
          textTransform: 'capitalize',
          minWidth: '100px',
          marginRight: '2rem',
        }}
      >
        {i18n.language === 'en'
          ? t('English')
          : i18n.language === 'kr'
          ? t('کوردی')
          : t('عربی')}
      </Button>
    </Dropdown>
  );
};

export default LanguageSelector;
