/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect, useState } from 'react';
import API_URL, { CHAT_URL } from './constants';

const auth = localStorage.getItem('cs-token');

if (!auth && window.location.pathname !== '/login') {
	window.location.href = '/login';
}

const authObject = JSON.parse(auth);

// chat axios api

const axiosChatInstance = axios.create({
	baseURL: `${CHAT_URL}/api`,
	headers: {
		authorization: authObject ? `Bearer ${authObject.value}` : '',
	},
});

export const useAxiosGetChat = (
	url,
	params = { autoRun: false },
	headers = {
		requestsource: 'b2b_dashboard',
	},
) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const request = async (queryParams = params) => {
		try {
			setLoading(true);
			const response = await axiosChatInstance.get(url, {
				params: queryParams,
				headers,
			});
			setData(response.data);
			return response.data;
		} catch (err) {
			setError(err);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (params.autoRun) {
			request();
		}
	}, []);

	return {
		data,
		setData,
		loading,
		error,
		request,
	};
};

export const useAxiosChatDelete = (
	url,
	params = { autoRun: false },
	headers = {
		requestsource: 'b2b_dashboard',
	},
) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const request = async (queryParams = params, deleteId = '') => {
		try {
			setLoading(true);
			const response = await axiosChatInstance.delete(url + deleteId, {
				params: queryParams,
				headers,
			});
			setData(response.data);
			return response.data;
		} catch (err) {
			setError(err);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (params.autoRun) {
			request();
		}
	}, []);

	return {
		data,
		setData,
		loading,
		error,
		request,
	};
};

export const useAxiosPostChat = (
	url,
	body,
	params = { autoRun: false },
	headers = {
		requestsource: 'b2b_dashboard',
	},
) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const request = async (
		bodyParams = body,
		queryParams = params,
		headersParams = headers,
	) => {
		try {
			setLoading(true);
			const response = await axiosChatInstance.post(url, bodyParams, {
				params: queryParams,
				headers: headersParams,
			});
			setData(response.data);
			return response.data;
		} catch (err) {
			setError(err);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (params.autoRun) {
			request();
		}
	}, []);

	return {
		data,
		setData,
		loading,
		error,
		request,
	};
};
export const useAxiosChatPut = (
	url,
	body,
	params = { autoRun: false },
	headers = {
		requestsource: 'b2b_dashboard',
	},
) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const request = async (bodyParams = body, queryParams = params) => {
		try {
			setLoading(true);
			const response = await axiosChatInstance.put(url, bodyParams, {
				params: queryParams,
				headers,
			});
			setData(response.data);
			return response.data;
		} catch (err) {
			setError(err);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (params.autoRun) {
			request();
		}
	}, []);

	return {
		data,
		setData,
		loading,
		error,
		request,
	};
};
