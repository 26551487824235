import React, { useState } from 'react';
import {
	Form, Input, Button, Card,
} from 'antd';

const ResetPasswordComponent = () => {
	const [loading, setLoading] = useState(false);

	const onFinish = (values) => {};

	return (
		<Card
			title="Enter your new password"
			style={{
				fontSize: '40px',
				width: 500,
				padding: '50px 20px',
				backgroundColor: '#F8F8F8',
			}}
		>
			<Form layout="vertical" onFinish={onFinish}>
				<Form.Item
					name="confirm_password"
					label="New Password"
					dir="ltr"
					rules={[{ required: true, message: 'Password is required' }]}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item
					name="confirm_password"
					label="Confirm New Password"
					dir="ltr"
					rules={[{ required: true, message: 'Password is required' }]}
				>
					<Input.Password />
				</Form.Item>

				<Button
					htmlType="submit"
					block
					type="primary"
					loading={loading}
					style={{ backgroundColor: '#1890ff', border: 'none' }}
				>
          Change Password
				</Button>
			</Form>
		</Card>
	);
};

export default ResetPasswordComponent;
