import axios from 'axios';

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_AXIOS_DEVELOPMENT_BASE_URL}/api`,
	headers: {
		'Content-Type': 'application/json',
		'request-source': 'Admin-App',
	},
});

export const login = async (username, password) => instance.post('/auth/login', {
	username,
	password,
});
// hhh
export const updatePassword = async (username, oldPassword, newPassword, token) => instance.patch('/auth/updatePassword', {
	username,
	oldPassword,
	newPassword,
}, {
	headers: {
		Authorization: `Bearer ${token}`,
	},

});

export const resetPassword = async (username, newPassword, token) => instance.patch('/auth/resetPassword', {
	username,
	newPassword,
}, {
	headers: {
		Authorization: `Bearer ${token}`,
	},

});
