import React, { Suspense, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import Dashboard from './components/layout/dashboard';
import AuthGuard from './components/AuthGuard';
import './assets/styles/global.css';
import AskForHelp from './components/Help/AskForHelp';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('en'); // Set default language to English
  }, [i18n]);

  return (
    <Suspense fallback="loading...">
      <div className="main-wrapper">
        <ConfigProvider
          //       theme={{
          //         token: {
          //           // Seed Token
          //           colorPrimary: '#001529',

          // }
          //       }}

          direction={i18n.language === 'en' ? 'ltr' : 'rtl'}
        >
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        </ConfigProvider>
      </div>
    </Suspense>
  );
}

export default App;
