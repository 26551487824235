import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, Input, Row, Col, Card, Spin, message } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import AskForHelpCard from './AskForHelpCard';
import './askForHelp.css';
import ImagePreview from '../../plugins/ImagePreview';
import { askForHelp, askForHelpContacts } from '../../api/askForHelp';
import store from '../../store';

const AskForHelp = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manager, setManager] = useState(null);
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const token = store.tokenStore.value;

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const body = {
      taskName: values.subject,
      description: values.description,
      imgUrl: values.screen_shot,
    };

    try {
      await askForHelp(body, token);
      setLoading(false);
      message.success(t('Sent Successfully'));
      form.resetFields();
    } catch (e) {
      message.error(t('some_thing_went_wrong'));

      setLoading(false);
    }

    onCloseDrawer();
  };

  const getDrawerWidth = () => {
    switch (true) {
      case screens.xs:
        return '75%';
      case screens.sm:
      case screens.md:
        return '400px';
      case screens.lg:
        return '400px';
      default:
        return '400px';
    }
  };

  const getContacts = async () => {
    try {
      const { data } = await askForHelpContacts(token);
      setManager(data);
    } catch (error) {
      message.error(t('some_thing_went_wrong'));
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <div>
      <div
        style={{
          right: i18n.language == 'en' ? '-20px' : '',
          left: i18n.language !== 'en' ? '-20px' : '',
        }}
        className="ask-for-help-btn"
      >
        <button onClick={showDrawer} className="help-btn">
          {t('need_help')}
        </button>
      </div>

      <Drawer
        title={<span className="drawer-title">{t('welcome_to_support')}</span>}
        width={getDrawerWidth()}
        onClose={onCloseDrawer}
        open={drawerVisible}
        placement={i18n.language == 'en' ? 'right' : 'left'}
        destroyOnClose
        style={{
          height: '90vh',
          overflowY: 'auto',
          position: 'absolute',
          top: '40%',
          transform: 'translateY(-40%)',
          boxShadow: 'none',
          borderRadius: '10px',
          background: '#f5f7f9   ',
        }}
      >
        <div style={{ marginTop: '1rem' }}>
          <AskForHelpCard title={t('got_a_problem')} index="1">
            <Form
              loading={loading}
              style={{ marginTop: '1rem' }}
              layout="vertical"
              onFinish={onFinish}
              form={form}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="subject"
                    label={t('subject')}
                    rules={[
                      {
                        required: true,
                        message: t('your_subject'),
                      },
                    ]}
                    className="bold"
                  >
                    <Input placeholder={t('your_subject')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label={t('description')}
                    className="bold"
                    rules={[
                      {
                        required: true,
                        message: t('your_subject'),
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} placeholder={t('your_message')} style={{ resize: 'none' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label={t('screen_shot')} name="screen_shot">
                    <ImagePreview form={form} formName="screen_shot" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      style={{ width: '100%', background: '#001529' }}
                    >
                      {t('send')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </AskForHelpCard>

          {manager && (
            <AskForHelpCard title={t('your_contact_manager')} index="2">
              <Card style={{ marginTop: '1rem', background: '#f3f5f7' }}>
                <p>
                  <span className="bold">{t('name')}:</span> {manager.user_name}
                </p>
                <p>
                  <span className="bold">{t('email')}:</span> {manager.email}
                </p>
                <p>
                  <span className="bold">{t('phone')}:</span> {manager.phone_number}
                </p>
              </Card>
            </AskForHelpCard>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default inject('tokenStore')(observer(AskForHelp));
